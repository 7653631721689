import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'
import type { ButtonProps } from '../../../molecules/button'
import type { PillProps } from '../../../molecules/pill'

import { mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'
import { Gallery } from 'react-photoswipe-gallery'
import Sticky from 'react-stickynode'

import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Title, { TitleSize } from '../../../atoms/title'
import { Advertisement, AdvertisementGroup } from '../../../molecules/advertisement'
import Button from '../../../molecules/button'
import Pill from '../../../molecules/pill'
import Container from '../../container'
import { onBeforeOpen, onOpen, options } from '../../mastermedia'
import Navigation from '../../navigation'

import styles from './articles.module.scss'

interface ArticleMatchPageProps {
  title: string,
  publicationDate: string,
  publicationTime: string,
  header: any[],
  main: any[],
  mainBody: any[],
  asideHead: any[],
  aside: any[],
  asideFooter: any[],
  tags: PillProps[],
  shareLinks?: ButtonProps[],
  permalink?: string,
  apiBaseUrl?: string,
  designSystemBaseUrl?: string,
  bffBaseUrl?: string,
  className?: ClassValue
}

const ArticleMatchPage: FunctionComponent<ArticleMatchPageProps> = (
  {
    title,
    publicationDate,
    publicationTime,
    header,
    main,
    mainBody,
    asideHead,
    aside,
    asideFooter,
    tags,
    shareLinks = [],
    permalink,
    apiBaseUrl,
    designSystemBaseUrl,
    bffBaseUrl,
    className
  }
) => {
  return <Container className={clsx(
    'sw-article-layout',
    className
  )}>
    <div className={styles.grid}>
      <Gallery withCaption onBeforeOpen={onBeforeOpen} onOpen={onOpen} options={options}>
        <div>
          <div id={'content'} className={clsx(
            'sw-article-layout-main',
            styles.main
          )}>
            {
              title
              && <Title
                tag="h1"
                size={TitleSize.Large}
                className={styles.title}
              >{title}</Title>
            }

            <div className={styles.publicationData}>
              <Meta>
                <Icon name="calendar" ariaHidden/>&nbsp;
                {publicationDate}
              </Meta>
              <Meta>
                <Icon name="clock" ariaHidden/>&nbsp;
                {publicationTime}
              </Meta>
            </div>

            <div className={styles.header}>
              {
                header?.map((item: any, index: number) =>
                  mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
                )
              }
            </div>

            <Sticky
              enabled={true}
              top={190}
              bottomBoundary=".sw-article-layout-main"
              className={styles.meta}
              innerClass={styles.metaInner}
              innerActiveClass={styles.metaActive}
            >
              <Navigation
                variant="main"
                items={[
                  ...shareLinks,
                  {
                    iconBefore: 'link',
                    ariaLabel: 'deel link kopieren',
                    showOnMobile: !!permalink,
                    showOnDesktop: !!permalink,
                    onClick: () => {
                      permalink && navigator?.clipboard?.writeText(permalink)
                    }
                  },
                ]}
                className={styles.share}
              />
            </Sticky>

            <div className={styles.mainContent}>
              {
                main?.map((item: any, index: number) =>
                  mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, useOutset: true })
                )
              }
            </div>
          </div>
          <div className={clsx(styles.asideHead, styles.mobileOnly, 'vrt-aside--head')}>
            <button>aside head</button>
            {
              asideHead?.map((item: any, index: number) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
          </div>
          <div className={styles.mainBody}>
            <div className={styles.mainContent}>
              {
                mainBody?.map((item: any, index: number) =>
                  mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, useOutset: true })
                )
              }

              <div className={clsx(
                styles.tags,
                'sw-article-tags'
              )}>
                {
                  tags?.map(tag => <Pill key={tag.label} {...tag} iconAfter="chevron-right"
                                         className="sw-article-tag"/>)
                }
              </div>

              <div className={styles.contact}>
                <Button
                  href="https://sporza.be/nl/taalfout-opgemerkt/"
                  target="_blank"
                  iconAfter="typo"
                >taal- of schrijffout
                  opgemerkt?</Button>
              </div>
            </div>
          </div>
          <div className={clsx(styles.aside, styles.mobileOnly, 'vrt-aside--body')}>
            {
              aside?.map((item: any, index: number) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
          </div>
          <div className={styles.mainFooter}>
            {
              // TODO: check if we still need this
            }
          </div>
          <div className={clsx(styles.asideFooter, styles.mobileOnly, 'vrt-aside--footer')}>
            {
              asideFooter?.map((item: any, index: number) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
          </div>
        </div>
        <div>
          <div className={clsx(styles.asideHead, 'vrt-aside--head')}>
            {
              asideHead?.map((item: any, index: number) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
          </div>
          <div className={clsx(styles.aside, 'vrt-aside--body')}>
            {
              aside?.map((item: any, index: number) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
          </div>
          <div className={clsx(styles.asideFooter, 'vrt-aside--footer')}>
            {
              asideFooter?.map((item: any, index: number) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
          </div>
        </div>
      </Gallery>
    </div>

    <AdvertisementGroup>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
    </AdvertisementGroup>
  </Container>
}

export {
  ArticleMatchPage
}

export type {
  ArticleMatchPageProps
}
