import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'
import type { Icons } from '../../../design-tokens/iconography/icons'
import type { ButtonProps } from '../../../molecules/button'

import { mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'
import { useState } from 'react'

import Caption from '../../../atoms/caption'
import Hidden from '../../../atoms/hidden'
import Icon from '../../../atoms/icon'
import Meta, { MetaWeight } from '../../../atoms/meta'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import { Advertisement, AdvertisementGroup } from '../../../molecules/advertisement'
import Button from '../../../molecules/button'
import Pill from '../../../molecules/pill'
import Container from '../../container'

import styles from './videos.module.scss'

interface VideoPageProps {
  title: string,
  publicationTime: string,
  header: any[],
  main: any[],
  mainBody: any[],
  asideHead: any[],
  aside: any[],
  tags: any[],
  apiBaseUrl?: string,
  designSystemBaseUrl?: string,
  className?: ClassValue,
  permalink?: string
  shareLinks?: ButtonProps[]
  formattedDuration?: string
  publicationDate?: string
}

const VideoPage: FunctionComponent<VideoPageProps> = (
  {
    title,
    publicationDate,
    header,
    main,
    mainBody,
    asideHead,
    aside,
    tags,
    apiBaseUrl,
    designSystemBaseUrl,
    className,
    permalink,
    shareLinks = [],
    formattedDuration,
  }
) => {
  const [shareLinkIcon, setShareLinkIcon] = useState<Icons>('link')

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(permalink + '?utm_medium=permalink')
      setShareLinkIcon('check-alt')
      setTimeout(() => setShareLinkIcon('link'), 3000)
    } catch (err) {
      console.error('link kon niet worden gekopieerd : ', err)
    }
  }

  return <Container className={clsx(
    'sw-video-layout',
    className
  )}>
    <Hidden tag={TitleElement.H1}>{title}</Hidden>
    <div className={styles.grid}>
      <div>
        <div className={clsx(styles.main, 'sw-video-layout-main')}>

          <div className={styles.header}>
            {
              header?.map((item: any) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
          </div>

          <div className={styles.mainContent}>
            <div className={styles.publicationData}>
              <Meta weight={MetaWeight.Medium}>
                <Icon name="video-timestamp" ariaHidden/>
                {formattedDuration}
              </Meta>
              <Meta weight={MetaWeight.Medium}>
                <Icon name="calendar" ariaHidden/>
                {publicationDate}
              </Meta>
            </div>

            {
              title &&
              <Title size={TitleSize.Large} className={styles.title}>
                {title}
              </Title>
            }
            <div className={styles.mainItems}>
              {
                main?.map((item: any) =>
                  mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
                )
              }
            </div>

            <div className={styles.tagsLinksWrapper}>
              {
                tags && tags.length > 0 &&
                <div className={clsx(styles.tags, 'sw-video-tags')}>
                  {
                    tags?.map(tag => <Pill key={tag.label} {...tag} iconAfter="chevron-right"
                                           className="sw-article-tag"/>)
                  }
                </div>
              }
              {
                shareLinks && shareLinks.length > 0 &&
                <div className={styles.shareLinks}>
                  <Caption weight={'Medium'} className={styles.shareLinksLabel}>video delen</Caption>
                  {
                    shareLinks?.map((link) =>
                      <Button
                        className={styles.shareLinksItem}
                        key={link.title}
                        href={link.href}
                        title={link.title}
                        iconBefore={link.iconBefore}
                        target="_blank"/>
                    )
                  }
                  {
                    permalink &&
                    <Button
                      className={clsx(styles.shareLinksItem, styles.copyLink)}
                      iconAfter={shareLinkIcon}
                      title="Deel via permalink"
                      onClick={() => handleCopy()}
                    />
                  }
                </div>
              }
            </div>
          </div>
        </div>
        <div className={clsx(styles.asideHead, styles.mobileOnly)}>
          {
            asideHead?.map((item: any) =>
              mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
            )
          }
        </div>
        <div className={clsx(styles.aside, styles.mobileOnly)}>
          {
            aside?.map((item: any) =>
              mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
            )
          }
        </div>
        <div className={styles.mainBody}>
          {
            mainBody?.map((item: any) =>
              mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
            )
          }
        </div>
        <div className={styles.mainFooter}>
        </div>
      </div>
      <div>
        <div className={styles.asideHead}>
          {
            asideHead?.map((item: any) =>
              mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
            )
          }
        </div>
        <div className={styles.aside}>
          {
            aside?.map((item: any) =>
              mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
            )
          }
        </div>
      </div>
    </div>

    <AdvertisementGroup>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
    </AdvertisementGroup>
  </Container>
}

export {
  VideoPage
}

export type {
  VideoPageProps
}
